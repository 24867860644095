<template>
    <div class="template-table" :class="{'no-filters': !availableFilters?.length}">

        <PageBack :to="pageBackUrl"/>

        <PageHeader :title="title" :subtitle="subtitle" :text="text">
            <template v-slot:actions>
                <slot name="actions"/>
                <ButtonExport v-if="!hideExport" @click.native.prevent="exportCsv" :hide="!table"/>
            </template>
        </PageHeader>

        <slot name="top"/>

        <PageFilters v-if="availableFilters?.length || $slots.right">

            <template v-slot:left v-if="availableFilters?.length && (displaySearch || displayDate || displaySelect || filtersSelected?.length)">
                <Field
                    v-if="displaySearch"
                    class="template-table__search"
                    type="text"
                    :model="searchTerm"
                    :placeholder="searchPlaceholder"
                    :label="$t('global.search')"
                    @change="e => searchTerm = e"/>
                <FieldDate
                    v-if="displayDate"
                    :label="$t('global.date')"
                    :model="date"
                    @change="e => date = e"/>
                <FiltersSelected
                    :selected="filtersSelected"
                    :filters="filters"
                    @delete="i => $refs.filtersPopin.removeField(i)"/>
                <FieldSelect
                    v-if="displaySelect"
                    class="template-table__displaySelect"
                    :label="displaySelect?.label"
                    :options="displaySelect.options"
                    :selected="displaySelect?.selectedItem"
                    :sorted="displaySelect?.sorted"
                    @input="displaySelect.updateFn"/>
            </template>

            <template v-slot:right>
                <slot name="right"/>
                <FiltersButton v-if="availableFilters?.length" @click.native="displayFiltersPopin = true"/>
            </template>

        </PageFilters>

        <FiltersPopin v-if="availableFilters && availableFilters.length"
            :visible="displayFiltersPopin"
            :filters="availableFilters"
            @close="displayFiltersPopin = false"
            ref="filtersPopin"/>

        <TextSimple v-if="table && !table.items">{{ $t('global.no-results-found') }}</TextSimple>

        <TableSticky v-else-if="displayTable"
            :fields="availableFields"
            :data="table ? table.items : null"
            :pagination="table ? table.pagination : null"
            :defaultItemsPerPage="defaultItemsPerPage"
            :filters="availableFilters"/>

        <slot name="default"/>

    </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import PageHeader from '@/components/ui/page/PageHeader';
import ButtonExport from '@/components/ui/button/ButtonExport';
import PageFilters from '@/components/ui/page/PageFilters';
import Field from '@/components/ui/form/fields/Field';
import FiltersButton from '@/components/ui/filters/FiltersButton';
import FiltersPopin from '@/components/ui/filters/FiltersPopin';
import TableSticky from '@/components/ui/table-sticky/TableSticky';
import FieldDate from '@/components/ui/form/fields/FieldDate';
import FiltersSelected from '@/components/ui/filters/FiltersSelected';
import PageBack from '@/components/ui/page/PageBack';
import TextSimple from '@/components/ui/text/TextSimple';
import { getFiltersUrl } from '@/utils';
import FieldSelect from '@/components/ui/form/fields/FieldSelect';

const placeholderPerName = {
    'resales-all': ['FaumeID', 'user', 'client'],
    'resales-waiting-shooting': ['client'],
    'resales-waiting-review': ['client'],
    'resales-waiting-publication': ['client'],
    'resales-photos': ['client', 'shopifyId'],
    'resales-shipping': ['FaumeID', 'user', 'client'],
    'resales-bookings': ['client', 'user', 'realStore'],
    'customers-all': ['client', 'user', 'firstname', 'lastname'],
    'customers-wallets': ['client', 'user', 'firstname', 'lastname'],
    'vouchers': ['code', 'client', 'user'],
    'orders': ['id', 'client', 'user'],
    'orders-articles': ['shopifyId', 'shopifyTitle', 'client', 'user'],
    'orders-returns': ['shopifyId', 'shopifyTitle', 'client', 'user'],
    'orders-returned': ['id', 'client', 'user'],
    'resales-credited': ['shopifyId', 'shopifyTitle', 'client', 'user'],
    'resales-stocks': ['client'],
    'promotions-debits': ['client', 'user'],
    'invoices': ['client', 'number', 'clientAgent'],
    'warehouse-inventory': ['client', 'shopifyId'],
    'warehouse-inventory-refused': ['client', 'shopifyId'],
}

export default {
    name: 'TemplateTable',
    components:  { FieldSelect, TextSimple, PageBack, FiltersSelected, FieldDate, TableSticky, FiltersPopin, FiltersButton, Field, PageFilters, ButtonExport, PageHeader },
    props: {
        title: String,
        subtitle: String,
        text: String,
        name: String,
        url: String,
        filters: Array,
        fields: Array,
        table: Object,
        actions: Array,
        displayDate: Boolean,
        displaySelect: Object,
        hideExport: Boolean,
        displayPageBack: Boolean,
        pageBackUrl: Object,
        defaultItemsPerPage: Number,
        noCache: Boolean,
        displayTable: {
            type: Boolean,
            default: true
        },
        autoRefresh:{
            type: Boolean,
            default: false
        },
        noFilters: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            displayFiltersPopin: false,
            searchTerm: null,
            date: this.$route.query.dateStock || moment().subtract(1, "days").format('YYYY-MM-DD'),
            filtersUrl: null,
            exportFiltersUrl: null,
            isInit: false,
            refreshInterval: false,
        }
    },
    computed: {
        ...mapState(['filtersSelected', 'temporaryUser']),
        ...mapGetters(['isClient']),
        displaySearch() {
            return !!this.filters && this.filters?.find(f => f.key === 'search')
        },
        availableFields() {
            if (this.isClient && this.fields) return this.fields.filter(f => f.key !== 'client');
            return this.fields
        },
        availableFilters() {
            let filters = this.filters ? this.filters.filter(f => f.key !== 'search' && (f.type !== 'select' || f.options?.length)) : null;
            if (filters && this.isClient) filters = filters.filter(f => f.key !== 'client');
            return filters
        },
        currentPage() {
            return Number(this.$route.query.page) || 1
        },
        searchPlaceholder() {
            let words = placeholderPerName[this.$route.name];
            if (words && words.length) return words.map(x => this.$t(`global.${x}`)).join(', ')
            return null
        },
        tableInfo(){
            return {
                name: this.name,
                url: this.url,
                filters: this.filtersUrl
            }
        }
    },
    methods: {
        ...mapActions(['getTable', 'resetTable', 'getTableFilters', 'exportTable']),
        getTableContent() {
            if( this.autoRefresh ){
                this.startAutoRefresh();
            }

            this.getTable({...this.tableInfo});
            this.reset();
        },
        reset() {
            this.resetTable(this.name);
        },
        exportCsv() {
            this.exportTable({...this.tableInfo})
        },
        startAutoRefresh(){
            this.removeAutoRefreshIfExist();
            this.refreshInterval = setInterval(() => {
                this.getTable({...this.tableInfo});
            }, 5 * 60 * 1000);
        },  
        removeAutoRefreshIfExist(){
            if( this.refreshInterval ) clearInterval(this.refreshInterval);
        }
    },
    beforeDestroy(){
        this.removeAutoRefreshIfExist();
    },
    watch: {
        autoRefresh(value){
            if( value ){
                // Force an immediate autorerefresh
                this.getTable({...this.tableInfo});
                this.startAutoRefresh();
            }else{
                this.removeAutoRefreshIfExist();
            }
        },
        temporaryUser() {
            this.getTableContent()
        },
        searchTerm(value, old) {
            let query = {...this.$route.query};
            if (value) query.search = value;
            else delete query.search;
            if (value || old) this.$router.push({query: {...query}});
        },
        date(value) {
            this.$router.push({query: { ...this.$route.query, dateStock: value}});
        },
        '$route.query.search': {
            handler(x) {
                this.searchTerm = x
            },
            deep: true,
            immediate: true
        },
        '$route.query.dateStock': {
            handler(x) {
                if (x) this.date = x
            },
            deep: true,
            immediate: true
        },
        '$route.query': {
            handler() {
                const filtersUrl = getFiltersUrl();
                if (filtersUrl !== this.filtersUrl) {
                    this.filtersUrl = filtersUrl;
                    this.exportFiltersUrl = getFiltersUrl(true);
                    if (this.isInit) this.getTableContent();
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.displayDate && !this.$route.query.dateStock) this.$router.replace({query: { ...this.$route.query, dateStock: this.date}})
        if (!this.$route.query.page) {
            this.$router.replace({query: {...this.$route.query, page: 1}});
        }
        if (!this.$route.query.limit) {
            this.$router.replace({query: {...this.$route.query, limit: 10}});
        }
        if (!this.filtersUrl || !this.exportFiltersUrl) {
            this.filtersUrl = getFiltersUrl();
            this.exportFiltersUrl = getFiltersUrl(true);
        }
        if (!this.table || this.noCache) this.getTableContent();
        else if (this.table.current !== this.currentPage) this.getTableContent();
        if (!this.filters && !this.noFilters) this.getTableFilters({
            name: this.name,
            url: this.url
        });
        this.isInit = true;
    }
}
</script>

<style lang="scss">
.template-table {

    &__search {
        width: 300px;

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }

    &__displaySelect {
        margin-top: 0;
        max-width: 200px;
    }

    @media screen and (max-width: 1024px) {
        padding-bottom: 80px;

        .filters {
            background-color: transparent;
            backdrop-filter: none;
            padding: 0;

            .button {
                position: fixed;
                bottom: 10px;
                z-index: 2;
                height: 40px;
                text-transform: uppercase;
                font-size: 1.3rem;
                left: 50%;
                transform: translateX(-50%);

                svg {
                    width: 12px;
                }
            }
        }
    }

    &.no-filters {
        --filter-height: 0px;
    }
}
</style>